export const environment = {
    baseAsForm: false,
    fallback: "http://localhost:4200/login",
    baseUrl: "http://3.110.164.226/api/v1/",
    // fallback: "http://vendor.dingg.app/login",
    // baseUrl: "https://api.dingg.app/api/v1/"
    // fallback: "https://stg.dingg.app/login",
    // baseUrl: "https://stage.dingg.app/api/v1/"
};

// export const environment = {
//     baseAsForm: false,
//     fallback: "http://vendor.dingg.app/login",
//     baseUrl: "https://api.dingg.app/api/v1/"
// };
